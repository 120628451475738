<template>
  <div>
    <TTableAdvance
      :items="purchaseItems"
      :fields="itemFields"
      store="order.available_for_distribution"
      resource="purchases"
      title=""
      reloadable
      @click-reload="refresh"
      @click-clear-filter="clearFilter"
    >
      <template #append-actions="{ item }">
        <CCol class="px-1 text-center" col="12">
          <SButtonDistribution @click="clickDistribution(item.id)" />
          <TButtonEnter :to="lodash.getReferenceLink('purchase', `${item.purchase_id}`)" />
        </CCol>
      </template>

      <template #product="{ item }">
        <td>
          <SCardProductItems
            v-if="item"
            :item="item.product"
            widthAuto
            resource="/purchase/goods/products"
          />
        </td>
      </template>

      <template #purchase_id="{ item }">
        <td>
          <TMessage :content="item.purchase_id" />
        </td>
      </template>

      <template #remaining_distributed="{ item }">
        <td>
          <TMessage
            :bold="true"
            :content="item.remaining_distributed_quantity"
          />
        </td>
      </template>

      <template #distributed_quantity="{ item }">
        <td>
          <div class="d-flex flex-row">
            <TMessageNumber
              :value="item.distributed_quantity"
              class="px-2 font-weight-bold"
            />
            <span>/</span>
            <TMessageNumber
              :value="item.quantity"
              class="px-2 font-weight-bold"
            />
          </div>
        </td>
      </template>

      <template #date="{ item }">
        <td>
          <TTableAsForm
            :data="item"
            :fields="[
              { key: 'payment_due_date', label: 'Payment due date' },
              {
                key: 'expected_delivery',
                label: 'Expected delivery',
              },
            ]"
            :splitLeft="6"
            :addRowClasses="['pb-1']"
          >
            <template #payment_due_date>
              <TMessageDateTime dateOnly :content="item.payment_due_date" />
            </template>
            <template #expected_delivery>
              <TMessageDateTime dateOnly :content="item.expected_delivery" />
            </template>
          </TTableAsForm>
        </td>
      </template>
      <template #note="{ item }">
        <td>
          <TMessageText :value="item.note" :messageOptions="{ truncate: 3 }" />
        </td>
      </template>

      <template #purchase_id-filter>
        <TInputText
          :value.sync="filter.purchase_id"
          @update:value="filterChange"
          placeholder="Purchase Id"
        />
      </template>

      <template #product-filter>
        <TInputText
          :value.sync="filter.product_id"
          @update:value="filterChange"
          placeholder="Jancode"
        />
      </template>
    </TTableAdvance>

    <Distribution
      v-if="!lodash.isEmpty(purchase)"
      :show.sync="showDistribution"
      :organization="purchase.organization_id"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Distribution from "./components/Distribution.vue";

export default {
  data() {
    return {
      itemFields: [
        { key: "_", _style: "width: 45px" },
        { key: "product", label: "Product", _style: "min-width: 350px" },
        {
          key: "purchase_id",
          label: "Purchase Id",
          _style: "min-width: 250px",
        },

        {
          key: "remaining_distributed",
          label: "Goods not yet distributed",
          _style: "min-width: 150px",
        },

        {
          key: "distributed_quantity",
          label: "Distributed/Quantity",
          _style: "min-width: 150px",
        },

        {
          key: "date",
          label: "Dates",
          _style: "min-width: 380px",
        },
        {
          key: "note",
          label: "Note",
          _style: "min-width: 300px",
        },
      ],

      showDistribution: false,
      key: "add-item",
      filter: {
        available_for_distribution: 1,
        purchase_id: "",
        product_id: "",
      },
    };
  },

  mounted() {
    this.$store.dispatch(
      "order.available_for_distribution.fetch.if-first-time"
    );
  },

  computed: {
    ...mapGetters({
      purchase: "order.order_purchase.detail",
      purchaseItems: "order.available_for_distribution.list",
      purchaseItemLoading: "order.available_for_distribution.detail.loading",
      updating: "order.order_purchase.detail.updating",
      purchaseLoading: "order.order_purchase.detail.loading",
    }),

    loading() {
      return this.purchaseItemLoading || this.purchaseLoading;
    },
    debited_at() {
      return (
        this.purchase?.transactions?.find((x) => x.type_id == "debit-purchase")
          ?.created_at || ""
      );
    },
  },

  methods: {
    refresh() {
      this.$store.dispatch("order.available_for_distribution.fetch");
    },
    clickDistribution(item_id) {
      this.$store.commit("order.available_for_distribution.select", item_id);
      this.showDistribution = true;
    },

    updatePurchaseItem(field, value) {
      this.$store.dispatch("order.available_for_distribution.detail.update", {
        [field]: value,
      });
    },

    filterChange() {
      const filter = this.lodash.mapKeys(this.filter, function (value, key) {
        return `filter[${key}]`;
      });

      const filterFields = this.lodash.pickBy({
        ...filter,
      });
      this.$store.dispatch(
        "order.available_for_distribution.apply-query",
        filterFields
      );
    },

    clearFilter() {
      this.filter.purchase_id = "";
      this.filter.product_id = "";
      this.filter.status_id = "";
      this.filterChange();
    },
  },

  components: { Distribution },
};
</script>
